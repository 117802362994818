import React from 'react';
import { makeStyles, Grow, Paper, Box, Typography, Modal } from '@material-ui/core/';
import { AudioPlayerCustom, SkeletonText } from '../../../../components';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        width: '40%',
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: 30,
        overflowX: "scroll",
        [theme.breakpoints.down(900)]: {
            width: '90%'
        },
    },
    stats: {
        flex: 1,
        alignSelf: 'center',
        color: '#fc5621',
        fontWeight: '500',
    },
}));
const ModalCall = (props) => {
    const classes = useStyles();
    const {
        recordingfile,
        transcriptionText,
        openModal,
        handleClose,
        isLoadingCallTranscript,
        callTranscript,
    } = props;

    return (
        <Modal
            open={!!openModal}
            onClose={handleClose.bind(null, false)}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grow in={!!openModal}>
                <Paper className={classes.modal}>
                    <Box>
                        <Typography
                            variant='subtitle1'
                            className={classes.stats}
                            style={{ color: 'black', marginBottom: 15 }}
                        >
                            RECORDING CALLS
                        </Typography>
                        <AudioPlayerCustom src={openModal} />
                        <Typography
                            variant='subtitle2'
                            className={classes.stats}
                            style={{
                                color: 'black',
                                marginBottom: 15,
                                marginTop: 20,
                                fontWeight: '500',
                            }}
                        >
                            TRANSCRIPTION
                        </Typography>

                        {isLoadingCallTranscript && (
                            <SkeletonText viewBox='0 0 500 160' width='500' />
                        )}
                        {!isLoadingCallTranscript && (
                            <Typography
                                variant='body1'
                                className={classes.stats}
                                style={{
                                    color: 'black',
                                    marginBottom: 15,
                                    marginTop: 20,
                                    fontWeight: '400',
                                }}
                            >
                                {(openModal === 'no_file' && 'NO_AUDIO_FOUND') ||
                                    callTranscript?.message ||
                                    React.Children.toArray(
                                        callTranscript?.transcript?.map((tx) => {
                                            const key = Object.keys(tx);
                                            return (
                                                <>
                                                    <strong>{key[0]}:</strong> {tx[key]} <br />
                                                </>
                                            );
                                        })
                                    )}
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Grow>
        </Modal>
    );
};
ModalCall.propTypes = {
    recordingfile: PropTypes.any,
    transcriptionText: PropTypes.string,
    openModal: PropTypes.any.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoadingCallTranscript: PropTypes.bool.isRequired,
    callTranscript: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};
ModalCall.defaultProps = {
    transcriptionText:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took.',
    recordingfile: '',
};
export default ModalCall;
