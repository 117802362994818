import React, { useCallback } from 'react';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { getCallTrackingAction } from '../../Redux/actions/callTrackingAction';
import { callTrackingSelector,callTrackingPagesSelector,doesFilterRequestSelector } from '../../Redux/selectors';
import { View } from './view';

export const CallTracking = () => {
    const dispatch = useDispatch();
    const isFilterRequested = useSelector((state) => doesFilterRequestSelector(state));
    const didNumberElement = useRef(0);
    const callTracking = useSelector((state) => callTrackingSelector(state));
    const totalPages = useSelector((state) => callTrackingPagesSelector(state));
    const [selectedStartDate, setSelectedStartDate] = useState(dayjs().startOf('month'));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    const [callInfo, setCallInfo] = useState('callprofile');
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const dateStartFrom = dayjs().startOf('month').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');

        const unsubscribe = dispatch(
            getCallTrackingAction({
                callInfo,
                status: 'all',
                extension: '0',
                date_from: dayjs(selectedStartDate).format('YYYY-MM-DD'),
                date_to: dayjs(selectedEndDate).format('YYYY-MM-DD'),
                page,
                size: offset,
            })
        );
        return () => unsubscribe;
    }, []);

    const handleOnSearch = () => {

        dispatch(
            getCallTrackingAction({
                callInfo,
                status: 'all',
                extension: didNumberElement.current.value || '0',
                date_from: dayjs(selectedStartDate).format('YYYY-MM-DD'),
                date_to: dayjs(selectedEndDate).format('YYYY-MM-DD'),
                page,
                size: offset,
                isSearched: true
            })
        );
    };
    
    const handlePageChange = (page) => {
        dispatch(
            getCallTrackingAction({
                callInfo,
                status: 'all',
                extension: didNumberElement.current.value || '0',
                date_from: dayjs(selectedStartDate).format('YYYY-MM-DD'),
                date_to: dayjs(selectedEndDate).format('YYYY-MM-DD'),
                page,
                size: offset,
                isSearched: false
            })
        );
    };

    /**
     * Triggers the dispatcher to retrieve the call logs
     * @param {String} did did number
     * @return
     */
    const getCallLogsHandler = useCallback((did) => {
        dispatch(
            getCallTrackingAction({
                callInfo: 'calllog',
                status: 'all',
                extension: did,
                date_from: dayjs(selectedStartDate).format('YYYY-MM-DD'),
                date_to: dayjs(selectedEndDate).format('YYYY-MM-DD'),
                page,
                size: offset,
            })
        );
    });

    const setStartTimeHandler = (d) => setSelectedStartDate(d);
    const setEndTimeHandler = (d) => setSelectedEndDate(d);

    const handleIsOpen = () => setIsOpen((prev) => !prev);

    return (
        <View
            handleOnSearch={handleOnSearch}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setStartTimeHandler}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setEndTimeHandler}
            callTracking={callTracking}
            totalPages={totalPages}
            loading={isFilterRequested}
            getCallLogsHandler={getCallLogsHandler}
            isOpen={isOpen}
            handleIsOpen={handleIsOpen}
            handlePageChange={handlePageChange}
            ref={didNumberElement}
        />
    );
};
