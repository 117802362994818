import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Box,
    Typography,
    TableRow,
    TableCell,
    IconButton,
    Collapse,
    Grid,
    Paper,
    Chip,
} from '@material-ui/core/';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import Description from '@material-ui/icons/Description';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AudioPlayerCustom } from '../../../../components';
import clsx from 'clsx';
import dayjs from 'dayjs'
import PropTypes from 'prop-types';
import { getOrganizationSpecificStyles } from '../../../../consts/theme';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paper: {
        padding: 8,
        textAlign: 'center',
        margin: 6,
    },
    bold: {
        fontWeight: 700
    },
    red: {
        color: 'red'
    },
    borderRight: {
        borderRight: '1px solid #ccc'
    },
    collapseGridWidth: {
        width: '20rem'
    }
}));

const RecentCallRow = (props) => {
    const { row, extState, returnNameExt, openModalCall } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState("No Recording");

    const recordingInfo = (recordingURL) => {
        const recordingAudio = new Audio(recordingURL);

        recordingAudio.addEventListener('loadedmetadata', () => {
            if (recordingAudio.duration === 0 || recordingAudio.duration === Infinity || Number.isNaN(recordingAudio.duration)) {
                setRecordingStatus('No Recording');
            } else {
                setRecordingStatus('Audio Available');
            }
        });

        recordingAudio.addEventListener('error', () => {
            setRecordingStatus('No Recording');
        });

        recordingAudio.load();
    };

    useEffect(() => {
        if (row && row.recordingfile) {
            recordingInfo(row.recordingfile);
        }
    }, [row]);
    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton size='small' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row'>
                    {row.cnam} ({row.src})
                </TableCell>
                <TableCell>{row.dst}</TableCell>
                <TableCell>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        {row.disposition === 'BUSY' || row.disposition === 'FAILED' ? (
                            <PhoneMissedIcon style={{ color: '#fd6a68', marginRight: 10 }} />
                        ) : (
                            <PhoneForwardedIcon
                                style={{
                                    color: row.src === extState ? '#37ae59' : '#00affe',
                                    marginRight: 10,
                                }}
                            />
                        )}
                        <Box
                            className={clsx({
                                [classes.badgeAnswer]: row.disposition === 'ANSWERED',
                                [classes.badgeNoAnswer]: row.disposition === 'NO ANSWER',
                                [classes.badgeBusy]:
                                    row.disposition === 'BUSY' || row.disposition === 'FAILED',
                            })}
                        >
                            {row.disposition}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell style={{minWidth: "15rem"}}>
                    {row.calldate_date} - {row.calldate_time}
                </TableCell>
                <TableCell>{row.call_duration}</TableCell>
                <TableCell style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", minWidth: "19rem" }}>
                    {recordingStatus === 'No Recording' ? (
                    <Chip label={recordingStatus} style={{ backgroundColor: 'red', color: 'white' }} />
                ) : (
                    <AudioPlayerCustom src={row?.recordingfile} />
                )}
                    <IconButton
                        onClick={openModalCall.bind(null, row?.recordingfile || 'no_file')}
                        style={{
                            backgroundColor: '#438afe',
                            color: 'white',
                            fontSize: 15,
                            marginLeft: 20,
                        }}
                    >
                        <Description style={{ fontSize: 15 }} />
                    </IconButton>
                </TableCell> 
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={7}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box style={{
                            background: getOrganizationSpecificStyles().background,
                            padding: 10,
                            borderLeft: getOrganizationSpecificStyles().border,
                            borderRadius: '5px 0px 0px 5px'
                        }}>
                            <Grid container spacing={15} alignItems='center' wrap = "nowrap">    
                                <Grid md={3} className={[classes.borderRight, classes.collapseGridWidth]}>
                                    <List>
                                        <ListItem>
                                            <ListItemText
                                                primary={"AccountCode"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.accountcode || 'No records'}
                                                    classes={{primary: classes.red}}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"AmaFlags"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.amaflags || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"BillSec"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.billsec || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"CallDuration"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.call_duration || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"CallDate"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={dayjs(row.calldate).format('ddd, DD MM YYYY') }

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"CallDate_date"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.calldate_date}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Grid>
                                
                                {/* GRID */}
                                <Grid md={3} className={[classes.borderRight, classes.collapseGridWidth]}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Channel"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.channel || 'No records'}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Clid"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.clid || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"RecordingFile"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={'row.recordingfile'}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Outbound_cnam"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.outbound_cnam || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Dst"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.dst}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Dst_cname"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.dst_cnam}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Grid>

                                {/* GRID */}
                                <Grid md={3} className={[classes.borderRight, classes.collapseGridWidth]}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Lastapp"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.lastapp}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Lastdata"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.lastdata || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Dcontext"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.dcontext}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Did"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.did}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Uniqueid"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.uniqueid}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Userfield"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.userfield}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Grid>

                                {/* GRID */}
                                <Grid md={3} className={[classes.borderRight, classes.collapseGridWidth]}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Calldate_time"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.calldate_time}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Disposition"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.disposition || 0}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Cnam"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.cnam}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Cnum"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.cnum}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Src"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.src}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                primary={"Outbound_cnum"}
                                                classes={{primary: classes.bold}}
                                            />
                                            <ListItemSecondaryAction>
                                                <ListItemText
                                                    primary={row.outbound_cnum}

                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
RecentCallRow.propTypes = {
    row: PropTypes.object.isRequired,
    extState: PropTypes.string.isRequired,
    openModalCall: PropTypes.func.isRequired,
};
RecentCallRow.defaultProps = {};
export default RecentCallRow;
