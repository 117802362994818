import React from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Link,
    Typography,
    Container,
    makeStyles,
    InputAdornment,
    IconButton,
    Box,
    Divider,
} from '@material-ui/core/';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { BackdropProgress, SnackbarAlert } from '../../components';
import { organization } from '../../consts/organization';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `linear-gradient(to top, ${theme.palette.primary.main},${theme.palette.secondary.main})`,
        width: '100%',
        margin: 0,
        height: '100vh',
        maxWidth: '100%',
        display: 'flex',
    },
    container: {
        width: '100%',
        '@media (min-width:600px)': {
            width: '60%',
        },
        [theme.breakpoints.up('md')]: {
            width: '30%',
        },
        margin: 'auto',
        textAlign: 'center',
    },
    subcontainer: {
        backgroundColor: theme.palette.white,
        padding: 30,
        textAlign: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        height: 70,
        marginBottom: 10,
    },
    title: {
        fontWeight: 500,
        color: theme.palette.primary.main,
        marginBottom: 10,
    },
    divider: {
        width: 30,
        margin: 'auto',
        height: 1.8,
        backgroundColor: theme.palette.primary.main,
        marginBottom: 20,
    },
}));

const View = (props) => {
    const classes = useStyles();
    const {
        signIn,
        handleOnChangeSignIn,
        handleOnEnter,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
        handleOnSummit,
    } = props;

    const excludedOrganizationsForLogo = new Set(['sdkphone', 'jmhonda', 'xprintx', 'heydphone', 'uc1']);
    const excludedOrganizationsForOrgLogo = new Set(['callmephone', 'realmgo', 'ic2']);

    return (
        <Container className={classes.root}>
            <CssBaseline />
            <SnackbarAlert />
            <BackdropProgress />
            <div className={classes.container} onKeyDown={handleOnEnter}>
                {!excludedOrganizationsForLogo.has(organization().name) ? (
                    <img src={organization().logo} className={classes.logo} />
                ) : null}
                <Box boxShadow={3} className={classes.subcontainer}>
                    <Typography variant='h5' className={classes.title}>
                        Log In
                    </Typography>
                    <Divider className={classes.divider} />
                    {organization().orgLogo && !excludedOrganizationsForOrgLogo.has(organization().name) && (
                        <img src={organization()?.logoDark || organization().orgLogo} className={classes.logo} />
                    )}
                    <form className={classes.form} noValidate>
                        <TextField
                            size='small'
                            value={signIn.username}
                            onChange={handleOnChangeSignIn}
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            label='Email Address'
                            name='username'
                            autoComplete='email'
                            autoFocus
                        />
                        <TextField
                            size='small'
                            value={signIn.password}
                            onChange={handleOnChangeSignIn}
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='password'
                            type={showPassword ? 'text' : 'password'}
                            id='password'
                            autoComplete='current-password'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={'end'}>
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge={'end'}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={handleOnSummit}
                        >
                            Sign In
                        </Button>
                        <Link href='forgot-password' variant='body2'>
                            Forgot Password
                        </Link>
                    </form>
                </Box>
            </div>
        </Container>
    );
};
View.propTypes = {
    signIn: PropTypes.object.isRequired,
    handleOnChangeSignIn: PropTypes.func.isRequired,
    handleOnEnter: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    handleClickShowPassword: PropTypes.func.isRequired,
    handleMouseDownPassword: PropTypes.func.isRequired,
    handleOnSummit: PropTypes.func.isRequired,
};
export default View;
