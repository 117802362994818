/**utilitiesReducer */
export const isLoadingBackdropSelector = (state) => state.utilitiesReducer.isLoadingBackdrop;
export const isLoadingViewSelector = (state) => state.utilitiesReducer.isLoadingView;
export const snackBarSelector = (state) => state.utilitiesReducer.snackBar;
export const colorsSelector = (state) => state.utilitiesReducer.colors;
export const doesFilterRequestSelector = (state) => state.utilitiesReducer.doesFilterRequest;

/**--------- */
/** signInReducer */
export const tokenSelector = (state) => state.signInReducer.access_token;
export const extSelector = (state) => state.signInReducer.access_number;
export const organizationSelector = (state) => state.signInReducer.organization;

/**--------- */
/**  callReducer */
export const recentsCallListByExtSelector = (state) => state.callReducer.recentsCallListByExt;
export const pieDataCallSelector = (state) => state.callReducer.pieDataCall;
export const lineDataCallSelector = (state) => state.callReducer.lineDataCall;
export const boxDataCallSelector = (state) => state.callReducer.boxCalls;
export const callRecordFilesSelector = (state) => state.callReducer.callRecordFiles;
export const callTranscriptSelector = (state) => state.callReducer.callTranscript;
export const isLoadingCallTranscriptSelector = (state) => state.callReducer.isLoadingCallTranscript;

/**----------------- */
/** userReducer */
export const userListSelector = (state) => state.userReducer.userList;
export const userDataSelector = (state) => state.userReducer.userData;
/**--------------- */

/** Miscall */
export const miscallSelector = (state) => state.miscallReducer.miscalls;
export const roicallSelector = (state) => state.miscallReducer.roiCalls;
export const miscallStartFilterSelector = (state) => state.miscallReducer.miscallStartfilter;
export const miscallEndFilterSelector = (state) => state.miscallReducer.miscallEndfilter;
export const roiStartFilterSelector = (state) => state.miscallReducer.roiStartfilter;
export const roiEndFilterSelector = (state) => state.miscallReducer.roiEndfilter;

/** Call Tracking */
export const callTrackingSelector = (state) => state.callTrackingReducer.calls;
export const callTrackingPagesSelector = (state) => state.callTrackingReducer.totalPages;
