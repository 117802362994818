import {
    GET_CALL_PROFILES_COMPLETE,
    GET_CALL_LOG,
    COLLAPSE_LOADING,
} from '../../consts/actionTypes';

/**
 * @namespace
 * @property {Array}   calls                    - contains call profiles and call logs
 * @property {String}  calls.did                - extension
 * @property {Array}   calls.log                - Stores call logs
 * @property {Boolean} calls.isCollapseOpen     - shows indicator of loading
 */
const initialState = {
    calls: [],
    totalPages: 1,
};

export default function render(state = initialState, action) {
    switch (action.type) {
        case GET_CALL_PROFILES_COMPLETE:
            return {
                ...state,
                calls: action.calls,
                totalPages: action.totalPages
            };
        case GET_CALL_LOG:
            const calls = state.calls;
            const idx = state.calls.findIndex((i) => i.cnum === action.extension);
            const pushCallLogs = { logs: action.calls, ...calls[idx] };
            pushCallLogs.isLoading = false;
            const updateCalls = [...calls.slice(0, idx), pushCallLogs, ...calls.slice(idx + 1)];

            return {
                ...state,
                calls: updateCalls,
                totalPages: action.totalPages
            };

        case COLLAPSE_LOADING:
            // const cloneCalls = structuredClone(state.calls);
            const idxOf = action.idx;

            const updateIsCollapse = {
                ...state.calls[idxOf],
                isLoading: true,
            };

            updateIsCollapse.isCollapseOpen = action.isCollapseOpen;

            const cloneCalls = [
                ...state.calls.slice(0, idxOf),
                updateIsCollapse,
                ...state.calls.slice(idxOf + 1),
            ];

            return {
                ...state,
                calls: cloneCalls,
            };

        default:
            return state;
    }
}
