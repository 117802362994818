import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  withStyles,
} from '@material-ui/core/';
import { Pagination, SimpleCard, SkeletonList } from '../../components';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import PropTypes from 'prop-types';
import RowContent from './rowContent';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 4,
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  accordion: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '20%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '20%',
    color: theme.palette.text.secondary,
  },
  details: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  icon: {
    marginRight: 30,
    marginLeft: 10,
  },
  colorBusy: {
    color: red[500],
  },
  colorAnswered: {
    color: green[500],
  },
  colorAnsweredBack: {
    color: green[500],
  },
  colorNoAnswered: {
    color: red[600],
  },
  borderRight: {
    borderRight: '1px solid #ccc'
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  paper: {
    padding: 8,
    textAlign: 'center',
    margin: 6,
  },
  bold: {
    fontWeight: 700
  },
  red: {
    color: 'red'
  },
  borderRight: {
    borderRight: '1px solid #ccc'
  },
  scrollbar: {
    maxWidth: "100px",
    maxHeight: "40px",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: "1px"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0)",
      outline: "1px solid transparent"
    }

  },
  collapseGridWidth: {
        width: '20rem'
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "black",
    color: "white",
  }
}))(TableCell);

const View = (props) => {
  const classes = useStyles();
  const {
    recentsCallListState,
    expanded,
    extState,
    dateFilterComp,
    paginationHandler,
    isLoading,
  } = props;

  const [openRow, setOpenRow] = useState(null);

  const handleToggleOpen = (index) => {
    if (openRow === index) {
      setOpenRow(null);
    } else {
      setOpenRow(index);
    }
  };


  return (
    <div className={classes.container}>
      {/* Date Filter topbar */}
      {dateFilterComp}

      <Box className={classes.row} style={{ flexDirection: 'column' }}>
        <SimpleCard elevation={0} border={true} paddingZero={true} overflow={true}>
          <TableContainer>
            <Table className={classes.fontSize}>
              {!isLoading && <TableHead>
                <TableRow>
                  <StyledTableCell>Call Details</StyledTableCell>
                  <StyledTableCell>Date and Time</StyledTableCell>
                  <StyledTableCell>Src</StyledTableCell>
                  <StyledTableCell>Dst</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Duration</StyledTableCell>
                </TableRow>
              </TableHead>
              }
              <TableBody>
                {!isLoading &&
                  recentsCallListState?.result?.map((call, index) => {
                    return (
                      <>
                        <TableRow className={classes.root}>
                          <TableCell>
                            <IconButton size='small' onClick={() => handleToggleOpen(index)}>
                              {openRow === index ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </TableCell>

                          <TableCell component="th" scope='row' style={{ minWidth: '16rem' }}>
                            {call.calldate_date} - {call.calldate_time}
                          </TableCell>
                          <TableCell>{call.src}</TableCell>
                          <TableCell>{call.dst}</TableCell>
                          <TableCell>
                            <Box>
                              {call.src === extState ? (
                                call.disposition === 'ANSWERED' ? (
                                  <PhoneInTalkIcon
                                    className={clsx({
                                      [classes.icon]: true,
                                      [classes.colorAnswered]: true,
                                    })}
                                  />
                                ) : (
                                  <PhoneDisabledIcon
                                    className={clsx({
                                      [classes.icon]: true,
                                      [classes.colorNoAnswered]: call.disposition === 'NO ANSWER',
                                      [classes.colorBusy]:
                                        call.disposition === 'BUSY' || call.disposition === 'FAILED',
                                    })}
                                  />
                                )
                              ) : (
                                call.disposition === 'ANSWERED' ? (
                                  <PhoneInTalkIcon
                                    className={clsx({
                                      [classes.icon]: true,
                                      [classes.colorAnsweredBack]: true,
                                    })}
                                  />
                                ) : (
                                  <PhoneDisabledIcon
                                    className={clsx({
                                      [classes.icon]: true,
                                      [classes.colorNoAnswered]: call.disposition === 'NO ANSWER',
                                      [classes.colorBusy]:
                                        call.disposition === 'BUSY' || call.disposition === 'FAILED',
                                    })}
                                  />
                                )
                              )}
                              {call.disposition}
                            </Box>
                          </TableCell>
                          <TableCell>{call.call_duration}</TableCell>
                        </TableRow>

                        <TableRow expanded={expanded === index}>
                          <TableCell style={{ padding: 0 }} colspan={7}>
                            <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                              <RowContent call={call} classes={classes} />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {isLoading && <SkeletonList />}
              </TableBody>
            </Table>
          </TableContainer>
        </SimpleCard>
        <Pagination count={recentsCallListState?.total_pages} onChange={paginationHandler} />
      </Box>
    </div>
  );
};
View.propTypes = {
  recentsCallListState: PropTypes.array.isRequired,
  expanded: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  extState: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  paginationHandler: PropTypes.func.isRequired,
};
export default View;
