import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
    },
    content: {
        flex: 1,
        paddingBottom: '16px!important',
    },
    contentPadding: {
        padding: '0px',
    },
    border: {
        border: '1px solid #d9d9d9'
    },
    overflow: {
        overflowX: 'scroll',
        width: "100%"
    },
    scrollbarStyle: {
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 0px rgba(0,0,0,0)',
            backgroundColor: 'rgba(245, 245, 245, 0)',
        },
        '&::-webkit-scrollbar': {
            width: '0px',
            backgroundColor: 'rgba(245, 245, 245, 0)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            border: '0px solid rgba(85, 85, 85, 0)',
        }
    }
});

const SimpleCard = (props) => {
    const { children, paddingZero, overflow } = props;
    const classes = useStyles();

    return (
        <Card className={`${classes.root} ${props.border && classes.border} ${overflow && classes.overflow} ${classes.scrollbarStyle}`} elevation={props.elevation}>
          <CardContent className={`${classes.content} ${paddingZero && classes.contentPadding} ${classes.scrollbarStyle}`}>{children}</CardContent>
        </Card>
    );
};

export default SimpleCard;
