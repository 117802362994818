import React from "react";
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Link,
    Typography,
    Container,
    makeStyles,
    InputAdornment,
    IconButton,
    Box,
    Divider,
    CircularProgress,
} from "@material-ui/core/";
import { Tune, Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import { BackdropProgress, SnackbarAlert } from "../../components";
import { organization } from "../../consts/organization";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `linear-gradient(to top, ${theme.palette.primary.main},${theme.palette.secondary.main})`,
        width: "100%",
        margin: 0,
        height: "100vh",
        maxWidth: "100%",
        display: "flex",
    },
    container: {
        width: "100%",
        "@media (min-width:600px)": {
            width: "60%",
        },
        [theme.breakpoints.up("md")]: {
            width: "30%",
        },
        margin: "auto",
        textAlign: "center",
    },
    subcontainer: {
        backgroundColor: theme.palette.white,
        padding: 30,
        textAlign: "center",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        height: 70,
        marginBottom: 10,
    },
    title: {
        fontWeight: 500,
        color: theme.palette.primary.main,
        marginBottom: 10,
    },
    divider: {
        width: 30,
        margin: "auto",
        height: 1.8,
        backgroundColor: theme.palette.primary.main,
        marginBottom: 20,
    },
}));

const View = (props) => {
    const classes = useStyles();
    const {
        forgotPassword,
        handleOnChangeSignIn,
        handleOnEnter,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
        handleOnSummit,
        isTokenSend,
        showConfirmPassword,
        handleClickShowConfirmPassword,
    } = props;

    const {isLoading} = useSelector((state) => state.forgotPasswordReducer);

    const excludedOrganizations = new Set(["sdkphone", "jmhonda", "xprintx", "uc1"]);
    
    return (
        <Container className={classes.root}>
            <CssBaseline />
            <SnackbarAlert />
            <BackdropProgress />
            <div className={classes.container} onKeyDown={handleOnEnter}>
                {!excludedOrganizations.has(organization().name) ? (
                    <img src={organization().logo} className={classes.logo} />
                ) : null}
                <Box boxShadow={3} className={classes.subcontainer}>
                    <Typography variant='h5' className={classes.title}>
                        Forgot Password
                    </Typography>
                    <Divider className={classes.divider} />
                    {organization().orgLogo && organization().name !== "ic2" && (
                        <img
                            src={organization()?.logoDark || organization().orgLogo}
                            className={classes.logo}
                        />
                    )}
                    {!isTokenSend ? (
                        <p>
                            Enter your email address, and we will send you a code to reset your password
                        </p>
                    ) : (
                        <p>Please Enter Code We Sent to your email address to reset your password</p>
                    )}
                    <form className={classes.form} noValidate>
                        {isTokenSend ? (
                            <div>
                                <TextField
                                    size='small'
                                    value={forgotPassword.token}
                                    onChange={handleOnChangeSignIn}
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='token'
                                    label='Verification Code'
                                    name='token'
                                    autoComplete='email'
                                    autoFocus
                                />
                                <TextField
                                    size='small'
                                    value={forgotPassword.password}
                                    onChange={handleOnChangeSignIn}
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='password'
                                    label='Password'
                                    name='password'
                                    autoFocus
                                    type={`${showPassword ? "text" : "password"}`}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"}>
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge={"end"}
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    size='small'
                                    value={forgotPassword.confirmPassword}
                                    onChange={handleOnChangeSignIn}
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='email'
                                    label='Confirm Password'
                                    name='confirmPassword'
                                    autoFocus
                                    type={`${showConfirmPassword ? "text" : "password"}`}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"}>
                                                <IconButton
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge={"end"}
                                                >
                                                    {showConfirmPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        ) : (
                            <TextField
                                size='small'
                                value={forgotPassword.email}
                                onChange={handleOnChangeSignIn}
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                id='email'
                                label='Email Address'
                                name='email'
                                autoComplete='email'
                                autoFocus
                            />
                        )}

                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={handleOnSummit}
                            
                        >
                            {!isTokenSend ? "Send Code" : "Save"}
                            {isLoading && <CircularProgress size={20} style={{marginLeft:"1rem"}} color="inherit"/>}
                        </Button>
                    </form>
                </Box>
            </div>
        </Container>
    );
};
View.propTypes = {
    forgotPassword: PropTypes.object.isRequired,
    handleOnChangeSignIn: PropTypes.func.isRequired,
    handleOnEnter: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    handleClickShowPassword: PropTypes.func.isRequired,
    handleOnSummit: PropTypes.func.isRequired,
    isTokenSend: PropTypes.bool.isRequired,
    showConfirmPassword: PropTypes.bool.isRequired,
    handleClickShowConfirmPassword: PropTypes.func.isRequired,
};
export default View;
